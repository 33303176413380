<template>
  <div class="notice">
    <form action="/">
      <van-search v-model="searchValue" show-action placeholder="请输入走访人姓名搜索" @search="onSearch" @cancel="onCancel"/>
    </form>
    <!--        <top-bar :title="'工作任务提醒'" :left="true"></top-bar>-->
    <van-tabs v-model="activeName" class="tab" title-active-color="#027eff" color="#027eff">
      <van-tab name = '0' :title="role === 3? '社工任务' : ''" v-if="role === 1 || role === 3"></van-tab>
      <van-tab name = '1' :title="role === 3? '网格任务' : ''" v-if="role === 2 || role === 3"></van-tab>
    </van-tabs>
    <div class="cont" v-show="activeName === '0'">
      <van-list v-model="orgData.loading" :finished="orgData.finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <van-cell>
          <template #title>
            <p class="list_totalCount">共有{{orgData.totalCount}}条数据</p>
          </template>
        </van-cell>
        <van-cell @click="goInfo(item)" v-for="(item, index) in dataList" :key="index">
          <van-cell-group >
            <van-cell class="title" center>
              <van-row>
                <van-col :span="18">走访：{{item.name}}</van-col>
                <van-col :span="6" :style="{color:item.taskStatus == 0?'#E82F31': (item.taskStatus == -1? '#FDA772' : '#1A83FC')}">{{item.taskStatus == 0?'未完成':(item.taskStatus == -1? '强制完成' : '完成')}}</van-col>
              </van-row>
              <van-row>
                <van-col :span="20">{{item.address}}</van-col>
                <!--                  <van-icon name="arrow" />-->
              </van-row>
              <van-row>
                <van-col :span="16">{{item.targetStr}}</van-col>
                <van-col :span="8">任务次数：<span style="color: #FC8708">{{item.finished}}</span><span style="font-weight: bold">/</span><span style="color: #8CB3F6">{{item.times}}</span></van-col>
              </van-row>
            </van-cell>
            <van-cell class="bottom" center>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">截至时间：{{item.endTimeStr}}</van-col>
              </van-row>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">每次走访有效时间间隔为<span style="color: #E7292D;padding: 0 5px;">{{item.effectiveInterval}}</span>小时</van-col>
              </van-row>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">上次完成任务时间：{{item.handleTime}}</van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
    <div class="cont" v-show="activeName == '1'">
      <van-list v-model="gridData.loading" :finished="gridData.finished" finished-text="没有更多了" @load="getGridList" v-if="gridDataList.length > 0" offset="10">
        <van-cell>
          <template #title>
            <p class="list_totalCount">共有{{gridData.totalCount}}条数据</p>
          </template>
        </van-cell>
        <van-cell @click="goInfo(item)" v-for="(item, index) in gridDataList" :key="index">
          <van-cell-group >
            <van-cell class="title" center>
              <van-row>
                <van-col :span="18">走访：{{item.name}}</van-col>
                <van-col :span="6" :style="{color:item.taskStatus == 0?'#E82F31': (item.taskStatus == -1? '#FDA772' : '#1A83FC')}">{{item.taskStatus == 0?'未完成':(item.taskStatus == -1? '强制完成' : '完成')}}</van-col>
              </van-row>
              <van-row>
                <van-col :span="20">{{item.address}}</van-col>
                <!--                  <van-icon name="arrow" />-->
              </van-row>
              <van-row>
                <van-col :span="16">{{item.targetStr}}</van-col>
                <van-col :span="8">任务次数：<span style="color: #FC8708">{{item.finished}}</span><span style="font-weight: bold">/</span><span style="color: #8CB3F6">{{item.times}}</span></van-col>
              </van-row>
            </van-cell>
            <van-cell class="bottom" center>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">截至时间：{{item.endTimeStr}}</van-col>
              </van-row>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">每次走访有效时间间隔为<span style="color: #E7292D;padding: 0 5px;">{{item.effectiveInterval}}</span>小时</van-col>
              </van-row>
              <van-row>
                <van-col :span="24" style="margin-bottom: 5px">上次完成任务时间：{{item.handleTime}}</van-col>
              </van-row>
            </van-cell>
          </van-cell-group>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="gridDataList.length < 1"/>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import topBar from '@/components/topBar/topBar'

export default {
  components :{
    topBar
  },
  data() {
    return {
      activeName: '',
      orgData: {
        loading: false,
        finished: false,
        totalCount: 0,
        limit: 10,
        page: 0,
      },
      gridData: {
        loading: false,
        finished: false,
        totalCount: 0,
        limit: 10,
        page: 0,
      },
      dataList: [],
      gridDataList: [],
      role: '',
      searchValue: '',
    }
  },
  methods: {
    goInfo(item) {
      let dictId = item.dictId
      let type = item.type
      let targetId = item.targetId
      let street = item.street
      let houseId = item.houseId
      let status = item.taskStatus
      if (status == 0) {
        this.$router.push({path: '/visit-add', query: {tasks: '1',dictId,type,targetId,street,houseId}})
      }
    },
    //判断登陆人身份
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/dailytasks/role'),
        method: 'post',
      }).then(({data}) => {
        if (data.code == 0) {
          this.role = data.role
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    //社区任务列表
    getDataList() {
      this.orgData.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl(`/wxapp/dailytasks/org/list`),
        method: 'post',
        params: this.$http.adornParams({
          page: this.orgData.page,          //当前页码
          limit: this.orgData.limit,        //每页展示的记录数
          name: this.searchValue, // 搜索框内容,以名字作为查询条件
          type: 1,
          orgId: this.$orgId
          // orgId: 12986
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataList = this.dataList.concat(data.page.list)
          this.orgData.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.orgData.finished = true
          }
          // 加载状态结束
          this.orgData.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    //网格任务列表
    getGridList() {
      this.gridData.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/dailytasks/grid/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.gridData.page,          //当前页码
          limit: this.gridData.limit,        //每页展示的记录数
          name: this.searchValue, // 搜索框内容,以名字作为查询条件
          type: 1,
          orgId: this.$orgId
          // orgId: 12986
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.gridData.totalCount = data.page.totalCount
          this.gridDataList = this.gridDataList.concat(data.page.list)
          if (this.gridDataList.length == data.page.totalCount) {
            this.gridData.finished = true
          }
          // 加载状态结束
          this.gridData.loading = false;
          this.$toast.clear()
        }
        // this.$toast.fail(data.msg);
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    onSearch(val) {
      this.orgData.page = 0
      this.searchValue = val
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.orgData.page = 0
      this.dataList = []
      this.searchValue = ''
      this.orgData.finished = false
      this.getDataList()
    },
  },
  created () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getInfo()
    this.getDataList()
    this.getGridList()
  }
}
</script>

<style lang="scss" scoped>
.notice {
  padding-top: 100px;
  .tab {
    //border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
  }
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .van-cell {
      position: relative;
      padding: 15px 20px 0;
      margin-bottom: 30px;
      border-radius: 10px;
      .title {
        margin-bottom: 5px;
        .van-icon {
          font-size: 18px;
          margin-left: 50px;
        }
        .van-row:nth-child(1) .van-col:nth-child(1){
          color: #1881fe;
          font-size: 30px;
        }
        .van-row:nth-child(1) .van-col:nth-child(2){
          padding-left: 30px;
        }
        .van-row:nth-child(2) .van-col:nth-child(1){
          font-size: 32px;
        }
        .van-row:nth-child(3) .van-col:nth-child(1){
          font-size: 32px;
        }
      }
      .bottom {
        border-top: 1px solid #F1F1F1;
        font-size: 28px;
        color: #898989;
      }
    }
    [class*=van-hairline]::after {
      border: none;
    }
    .van-cell::after {
      border-bottom: none;
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
  .contTitle {
    font-size: 30px;
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #a2a2a2;
    span {
      color: #3D7EFE;
      font-size: 30px;
    }
  }
  .widthImg {
    height: 100%;
    img {
      width: 90px;
      height: 90px;
    }
  }
  .filterBar {
    display: flex;
    height: 80px;
    text-align: center;
    line-height: 80px;
    font-size: 30px;
    margin: 0 70px;
    span:nth-child(2) {
      flex: 1;
      //margin-left: 100px;
    }
  }

}
</style>
